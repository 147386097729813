
body:not(.page-tribe-attendee-registration) {
#tribe-events-pg-template, .tribe-events-pg-template {
	margin: 80px auto !important;
	max-width: 1100px !important;
	
}

.tribe-events-back, #tribe-events-footer, .tribe-events-cost, .tribe-events-cal-links, .tribe-events-event-categories, .tribe-events-event-categories-label, .tribe-events-event-cost-label, .tribe-events-event-cost,  .tribe-events-event-image {
	display: none !important;	
}

.tribe-link-view-attendee {
	padding: 15px 20px;
	background: #c4ffdb;
	font-weight: bold;
}

.tribe-formatted-currency-wrap, .tribe-currency-prefix {
	font-size: 1.3rem;
}

.tribe-tickets__item__extra__available {
	font-size: 0.9rem !important;
}
.tribe-common-h7 {
	font-size: 1.1rem !important;
}

.tribe-common a.tribe-common-c-btn--small, .tribe-common button.tribe-common-c-btn--small, .tribe-common input[type=button].tribe-common-c-btn--small {
	background-color: #05acc0 !important;
}

.tribe-common button.tribe-common-c-btn-link {
	background: transparent !important;
	color: #05acc0 !important;
	font-weight: bold !important;
}

.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt {
	background-color: #ec6525 !important;
}

ul.my-attendance-list {
	list-style: none;
	margin: 0 0 60px 0;
	padding: 0;

	li {
		margin: 0 0 10px 0 !important;
		padding: 10px 15px;
		background: #054160;
		color: #fff;
		border-left: solid 5px #05acc0;
		transition: background 0.15s linear;
		
		&:hover {
			background: #05acc0;
		}
		
		&:before {
			display: none;
		}
		
		color: #fff;
	}
	
	a {
		color: #fff !important;
		text-decoration: none !important;
		font-weight: bold;
	}
}

.tribe-orders-list {
	.list-attendee {
		display: none;
	}
}

.tribe-orders-list .tribe-tickets-list>.tribe-item {
	padding: 20px;
	border: 1px solid #ededed;
	border-bottom: 0;
	min-height: 67px;
}

.tribe-ticket-information {
	float: none !important;
	
	.ticket-name, .ticket-price {
		font-weight: bold;
		font-size: 22px;
		color: $blue;
	}
	
	.ticket-price {
		color: $orange;
	}
}

a.attendee-meta.toggle {
	font-size: 18px !important;
	margin-top: 10px !important;
	margin-bottom: 0px !important;
}

.tribe-submit-tickets-form button {
	background: $orange;
	padding: 1rem 1.6rem;
	border-radius: 5px;
	color: #fff;
	font-size: bold;
	font-size: 1.2rem;
	font-weight: bold;
}

.tribe-orders-list {
	label, .tribe-tickets-meta-label {
		font-weight: bold;
		color: $blue;
		margin-top: 20px !important;
	}
	
	label.tribe-tickets-meta-field-header, .tribe-tickets-meta-option-label {
		margin-top: 10px !important;
		font-size: 1rem !important;
	}
	
	.attendee-meta-row {
		clear: both;
		margin-top: 20px;
	}
	
	input[type="text"], textarea {
		display: block;
		width: 100%;
		border: solid #ededed 1px;
		margin: 5px 0;
		padding: 10px;
		
		&:focus {
			border: solid $blue 1px;
		}
	}
}

.tribe-checkout-backlinks {
	a {
		margin-right: 1rem;
		font-size: 1.2rem;
		color: $blue;
		font-weight: bold;
	}
}

.woocommerce-Input[type="text"], .woocommerce-Input[type="password"], textarea.woocommerce-Input {
	display: block;
	width: 100%;
	border: solid #ededed 1px;
	margin: 5px 0;
	padding: 10px;
	
	&:focus {
		border: solid $blue 1px;
	}
}
}