// ==============================================================================================================
//  Removes/resets all browser-specific styling
// ==============================================================================================================

// Ensure all elements don't expand their specified width with padding, borders etc.
*,
*:before,
*:after {
	box-sizing: border-box;
}

// Remove browser-specific styling (margin, padding etc.) from most major HTML elements
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, input, textarea, select, button,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	font-size: 1rem;
	font: inherit;
	vertical-align: baseline;
	margin: 0;
	padding: 0;
	border: 0;
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: top;
	border: 0;
}

ol, ul {
	list-style: none inside none;
}

input,
textarea {
	font: normal $regular 1rem/1 $fontStack;
	border-radius: 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
}

select {
	border-radius: 0;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

// Change colour of placeholder text in input fields
::-webkit-input-placeholder {
	color: $black;
	opacity: 1;
}

::-moz-placeholder {
	color: $black;
	opacity: 1;
}

:-ms-input-placeholder {
	color: $black;
	opacity: 1;
}

::-ms-input-placeholder {
	color: $black;
	opacity: 1;
}

// Change colour of text/background in highlighted text
::selection {
    color: $white;
    text-shadow: none;
    background-color: $blue;
}