// ==============================================================================================================
//  Defines styles for the header area
// ==============================================================================================================

header {

	.topbar {
		height: 55px;
		padding-top: 16px;
	}

	.secondary-menu {
		float: right;
		margin-top: 4px;
		display: flex;
		align-items: center;

		ul li {
			font-size: 0.982rem;
			color: $white;
			display: inline-block;
			margin-right: 34px;

			&:last-child {
				margin-right: 0;
			}

			a {
				text-decoration: none;
				color: $white;
			}
		}
	}

	.navbar {
		height: 145px;
		padding-top: 26px;
		box-shadow: 0px 2px 13.02px 0.98px rgba(0,0,0, 0.07);
	}
	
	.logo {
		z-index: 1000;
		position: relative;
	}
}

.social-menu {
	float: left;

	ul li {
		font-size: 1.55rem;
		display: inline-block;
		vertical-align: middle;
		margin-right: 5px;

		&:last-child {
			margin-right: 0;
		}

		a {
			text-decoration: none;
			color: $white;
		}
	}
}

.cart-customlocation {
	margin-right: 30px;
	color: #fff;
	text-decoration: none;
	line-height: 1;
	
	svg {
		width: 1rem;
		height: 1rem;
		vertical-align: top;
		margin-right: 0.5rem;
		
		path {
			fill: #fff;
		}
	}
}

.mega-sub-menu {
	.cta-button, .wpcf7 input[type="submit"], .woocommerce div.product .mini-course-booking form.cart .single_add_to_cart_button, .woocommerce div.product .full-course-booking form.cart .single_add_to_cart_button, .woocommerce-cart .wc-proceed-to-checkout a.checkout-button, .woocommerce-checkout .woocommerce #payment #place_order {
		position: relative;
		font-weight: 500;
		font-size: 1.1rem;
		text-decoration: none;
		color: #fff;
		display: inline-block;
		z-index: 1;
		padding: 8px 10px;
		border-radius: 5px; 
	}
}