// ==============================================================================================================
//  Defines styles for the main menu, including mega drop down
// ==============================================================================================================

.main-menu {
	float: right;
	margin-top: 36px;

	ul li {
		font-size: 1.186rem;
		color: $blue;
		display: inline-block;
		margin-right: 30px;

		&:last-child {
			margin-right: 0;
		}

		a {
			text-decoration: none;
			color: $blue;
		}

		// Drop down menu
		&.menu-item-has-children {
			position: relative;
			padding-bottom: 20px;

			> a {
				position: relative;

				&:after {
					content: '';
					position: relative;
					top: 2px;
					width: 11px;
					height: 11px;
					text-align: center;
					display: block;
					margin: auto;
					border-top: 2px solid $blue;
					border-right: 2px solid $blue;
					border-radius: 2px;
					transform: rotate(135deg);
					transition: all 0.4s ease-in-out;
				}
			}

			ul {
				position: absolute;
				top: 100%;
				left: 0;
				width: 220px;
				visibility: hidden;
				opacity: 0;
				z-index: 10;
				padding: 20px;
				border: 1px solid $blue;
				background-color: $white;
				transition: all 0.25s ease-in-out;

				li {
					font-size: 1.185rem;
					color: $blue;
					display: block;
					margin: 0 0 21px;

					&:last-child {
						margin-bottom: 0;
					}

					a {
						color: $blue;
					}
				}
			}

			.no-touchevents &:hover {
				> ul {
					visibility: visible;
					opacity: 1;
				}
			}
		}

		// &.current-menu-item,
		// &.current-page-ancestor,
 	// 	&.current-menu-ancestor {

 	// 		> a {
 	// 			color: $orange;

 	// 			&:after {
 	// 				border-color: $orange;
 	// 			}
 	// 		}
 	// 	}
	}

	@media screen and (max-width: 1000px) {
		display: none;
	}
}