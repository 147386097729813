body.search {
	a {
		text-decoration: none;
	}
	
	h2 {
		margin: 0 0 15px 0;
		font-weight: bold;
		
		&:hover {
			color: darken($blue, 20%);
		}
	}
	
	hr {
		margin-bottom: 30px;
		border: none;
		border-bottom: solid 1px $blue;
		opacity: 0.2;
	}
}