// ==============================================================================================================
//  Defines styles for the footer area
// ==============================================================================================================

section#newsletter {
	border-top: solid 1px rgba(0,0,0,0.1);
	padding: 50px 0 50px;
	
	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		
		@media(max-width: 950px) {
			flex-direction: column;
		}
		
		h2 {
			color: $pink;
			margin: 5px 10px;
			line-height: 1;
			text-align: center;
		}
		
		a {
			display: block;
			color: #fff;
			background: $pink;
			font-weight: bold;
			text-decoration: none;
			margin: 5px 15px;
			line-height: 1;
			font-size: 24px;
			padding: 10px 10px;
			border-radius: 5px;
			transition: background linear 0.15s;
			
			&:hover {
				background: darken($pink, 10%);
			}
		}
	}
}

footer {
	padding: 70px 0;

	.logo {
		margin-bottom: 40px;
	}

	p {
		color: $white;

		&.address {
			margin: 40px 0 30px;
		}

		a {
			color: $white;

			.no-touchevents &:hover { 
				opacity: 0.7;
			}
		}

		&.sign-off {

			a {
				text-decoration: none;
			}

			.separator {
				position: relative;
				top: -1px;
				display: inline-block;
				margin: 0 2px;
			}

			@media screen and (max-width: 860px) {
				font-size: 1.25rem;
			}
		}
	}

	.social-menu {
		float: none;
	}

	.useful-links-menu {
		margin-top: 40px;

		ul li {
			font-size: 1.438rem;
			line-height: 1.39;
			color: $white;

			a {
				text-decoration: none;
				color: $white;

				.no-touchevents &:hover {
					opacity: 0.7;
				}
			}
		}

		@media screen and (max-width: 768px) {
			margin-top: 18px;
		}
	}

	.accreditations {
		margin-top: 45px;

		a {
			margin-bottom: 20px;
		}

		@media screen and (max-width: 768px) {
			max-width: 370px;
			margin-top: 20px;
		}
	}

	.push-down {
		padding-top: 117px;

		@media screen and (max-width: 768px) {
			padding-top: 0;
		}
	}

	.cols-wrap {
		border-bottom: 2px solid $white;
	}

	.legal-links-menu {
		text-align: center;
		margin: 40px 0;

		ul li {
			font-size: 1.313rem;
			color: $white;
			display: inline-block;
			margin-right: 40px;

			&:last-child {
				margin-right: 0;
			}

			a {
				text-decoration: none;
				color: $white;

				.no-touchevents &:hover {
					opacity: 0.7;
				}
			}

			@media screen and (max-width: 860px) {
				font-size: 1.25rem;
				margin-right: 21px;
			}

			@media screen and (max-width: 768px) {
				display: block;
				margin: 0 0 10px;
			}
		}
	}

	@media screen and (max-width: 768px) {
		.footer-col {
			width: 100%;
			float: none;
			margin: 0 0 30px;
		}
	}
}