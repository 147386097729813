// ==============================================================================================================
//  Defines styles for resusable CTA buttons
// ==============================================================================================================

.cta-button-blocks {

	.cta-button-block {
		position: relative;
		width: 50%;
		min-height: 225px;
		margin-right: 0;
		padding: 0 20px;
		background-size: auto 100%;
		transition: background-size 0.3s ease-in-out;

		.no-touchevents &:hover {
			background-size: auto 110%;
		}

		a {
			color: $white;
			text-decoration: none;
			display: block;

			@media screen and (max-width: 680px) {
				padding-top: 70px;
			}
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			opacity: 0.9;
		}

		.button-text {
			position: relative;
			max-width: 295px;
			z-index: 2;
			margin: 70px auto 0;
			padding: 25px 10px;
			border: 3px solid $white;
			border-radius: 7px;

			@media screen and (max-width: 680px) {
				margin-top: 0;
			}
		}

		h3 {
			font-size: 1.698rem;
			color: $white;
			margin-bottom: 0;
		}

		@media screen and (max-width: 680px) {
			width: 100%;
			float: none;
			background-size: cover;

			.no-touchevents &:hover {
				background-size: cover;
			}
		}
	}
}