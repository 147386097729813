// ==============================================================================================================
//  Defines the container/grid/column sizes of the site
// ==============================================================================================================

.container {
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;
	padding-right: 20px;
	padding-left: 20px;

	&.narrow {
		max-width: 1140px;
	}

	@media screen and (max-width: 1180px) {

		& > .narrow {
			padding-right: 0;
			padding-left: 0;
		}
	}
}

// Grid columns
.half {
	width: calc(50% - 10px);
	float: left;
	margin-right: 20px;

	&:last-child,
	&:nth-child(2n+2) {
		margin-right: 0;
	}
}

.one-third {
	width: calc(33.333% - 13.333px);
	float: left;
	margin-right: 20px;

	&:last-child,
	&:nth-child(3n+3) {
		margin-right: 0;
	}
}

.one-quarter {
	width: calc(25% - 15px);
	float: left;
	margin-right: 20px;

	&:last-child,
	&:nth-child(4n+4) {
		margin-right: 0;
	}
}