// ==============================================================================================================
//  Reusable classes that can be used to avoid repitition and can be used throughout the entire site
// ==============================================================================================================

// Stop <body> from being scrollable
body.no-scroll {
	overflow: hidden;
}

// Vertical centre
.vertical-centre {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 auto;
	transform: translateY(-50%);
}

// Text sizes/alignment/colours etc.
.centre-text {
	text-align: center;
}

// Hide content on desktop devices (for mobile content)
.desktop-hide {
	display: none;
}

// Margin/padding
.marg-bot-80 {
	margin-bottom: 80px;

	@media screen and (max-width: 680px) {
		margin-bottom: 30px;
	}
}

.marg-bot-60 {
	margin-bottom: 60px;

	@media screen and (max-width: 680px) {
		margin-bottom: 30px;
	}
}

.pad-bot-30 {
	padding-top: 30px;
}

.pad-top-30 {
	padding-top: 30px;
}

.pad-top-60 {
	padding-top: 60px;

	@media screen and (max-width: 680px) {
		padding-top: 30px;
	}
}

.width-100 {
	width: 100%;
}

.pad-bot-60 {
	padding-bottom: 60px;

	@media screen and (max-width: 680px) {
		padding-bottom: 30px;
	}
}

.pad-big {
	padding: 50px !important;
	
	@media(max-width: 900px) {
		padding: 30px !important;
	}
}

.pad-top-80 {
	padding-top: 80px;

	@media screen and (max-width: 680px) {
		padding-top: 30px;
	}
}

.pad-bot-80 {
	padding-bottom: 80px;

	@media screen and (max-width: 680px) {
		padding-bottom: 30px;
	}
}

// Force element to clear its floated children
.clearfix:after {
	content: "";
	height: 0;
	display: block;
	visibility: hidden;
	clear: both;
}

// Background colours etc.
.blue-bg {
	background-color: $blue;
}

.turquoise-bg {
	background-color: $turquoise;
}

.dark-turquoise-bg {
	background-color: $darkTurquoise;
}

.orange-bg {
	background-color: $orange;
}

.pink-bg {
	background-color: $pink;
}

.grey-bg {
	background-color: $grey;
}

.dark-grey-bg {
	background-color: $darkGrey;
}

.white-bg {
	background-color: $white;
}

.cover {
	background: none center no-repeat transparent;
	background-size: cover;
}