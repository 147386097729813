// ==============================================================================================================
//  Defines styles for contact forms
// ==============================================================================================================

.wpcf7 {
	max-width: 550px;
	margin: 0 auto;

	h2 {
		margin-bottom: 40px;
	}

	form {
		position: relative;
	}

	label {
		font-size: 1.25rem;
		color: $blue;
		display: block;
		margin-bottom: 8px;
	}

	input,
	select,
	textarea {
		width: 100%;
		height: 50px;
		font-size: 1.313rem;
		color: $black;
		margin-bottom: 36px;
		padding: 0 10px;
		border: 1px solid transparent;
		background-color: $grey;

		.grey-bg & {
			background-color: $white;
		}

		@media screen and (max-width: 480px) {
			margin-bottom: 20px;
		}
	}

	textarea {
		min-height: 220px;
		padding: 10px;
		margin-bottom: 26px;

		@media screen and (max-width: 480px) {
			margin-bottom: 20px;
		}
	}

	input[type="radio"] {
		position: relative;
		top: -2px;
		width: 15px;
		height: 15px;
		font-size: 1rem;
		line-height: 1;
		margin: 0;
		padding: 0;
		border: 0;
		bacgkround-color: transparent;
	}

	input[type="checkbox"] {
		width: auto;
		height: auto;
		margin: 0;
		padding: 0;
		border: 0;
		background-color: $white;
	}

	// .wpcf7-form-control-wrap.over-18 .wpcf7-list-item.last input:checked {
	// 	width: 200px;
	// }

	.wpcf7-form-control.wpcf7-radio {
		display: inline-block;
		margin-bottom: 30px;
	}

	span.wpcf7-list-item {
		margin: 8px 24px 0 0;

		span {
			font-size: 1.25rem;
			color: $blue;
			display: inline-block;
			margin-left: 6px;
		}
	}

	input[type="submit"] {
		@extend .cta-button;
		@extend .centre-text;
		@extend .orange-bg;
		max-width: 200px;
		height: auto;
		line-height: 1;
		cursor: pointer;
		margin-bottom: 0;
		border: 0;

		.grey-bg & {
			@extend .orange-bg;
		}
	}

	p.gdpr {
		font-size: 1rem;
		margin-bottom: 26px;

		@media screen and (max-width: 480px) {
			margin-bottom: 20px;
		}
	}
}

span.wpcf7-not-valid-tip {
	display: none !important;
}

.wpcf7 input.wpcf7-not-valid,
.wpcf7 textarea.wpcf7-not-valid,
.wpcf7 select.wpcf7-not-valid {
	border-color: #d8000c;
}

div.wpcf7 div.wpcf7-response-output {
	width: 100%;
	font-weight: $regular;
	font-size: 1.313rem;
	font-family: $fontStack;
	line-height: 1.52;
	margin: 30px 0;
	padding: 16px 12px;
}

div.wpcf7 .wpcf7-response-output.wpcf7-validation-errors,
div.wpcf7 .wpcf7-response-output.wpcf7-mail-sent-ng {
	color: #d8000c;
	border: 2px solid #d8000c;
    background-color: #ffbaba;
}

div.wpcf7 .wpcf7-response-output.wpcf7-mail-sent-ok {
	color: #4f8a10;
	border: 2px solid #4f8a10;
	background-color: #dff2bf;
}

div.wpcf7 .ajax-loader {
	width: auto !important;
	height: auto !important;
	margin: 0 0 0 20px !important;
	background: none !important;
}

.ball-clip-rotate > div {
	height: 35px;
	width: 35px;
	display: block;
	border: 2px solid $blue;
	border-bottom-color: transparent;
	border-radius: 100%;
	animation: rotate 1s 0s linear infinite;
	animation-fill-mode: both;
}

@keyframes rotate {

	0% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(180deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotate {

	0% {
		transform: rotate(0deg) scale(1);
	}

	50% {
		transform: rotate(180deg) scale(0.6);
	}

	100% {
		transform: rotate(360deg) scale(1);
	}
}