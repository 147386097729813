// ==============================================================================================================
//  Defines styles specific to the Course Calendar page
// ==============================================================================================================

:root {
	--one-day-courses: #25a3ec;
	--online-courses: #05415f;
	--sailing-courses: #05acc0;
	--power-courses: #ec6425;
	--theory-courses: #ec1062;
	--commercial-courses: #ecb825;
	--specialist-courses: #c00587;
	--practical-courses: #00998E;
}



.course-calendar-page-content {

	#calendar-categories {
		width: 100%;
		margin: 0 0 40px 0;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		
		@media(max-width: 900px) {
			width: calc(100% + 40px);
			margin: 0 -20px 20px -20px;
			padding: 0 20px;
		}
		
		ul {
			display: flex;
			overflow: visible;
			margin: 0;
		}

		ul li {
			font-weight: $medium;
			font-size: 1.184rem;
			line-height: 1;
			color: $white;
			margin: 0 6px 6px 0;
			padding: 18px 15px;
			background-color: $blue;
			white-space: nowrap;
			
			@media(max-width: 900px) {
				font-size: 1rem;
				padding: 14px 11px;
			}

			&:before {
				display: none;
			}

			&:last-child {
				margin-right: 0;
			}

			&.one-day { background-color: var(--one-day-courses) !important; }
			&.online-courses { background-color: var(--online-courses) !important; }
			&.rya-practical-courses { background-color: var(--practical-courses) !important; }
			&.rya-theory-courses { background-color: var(--theory-courses) !important; }
			&.power-courses { background-color: var(--power-courses) !important; }
			&.commercial-courses { background-color: var(--commercial-courses) !important; }
			&.specialist-courses { background-color: var(--specialist-courses) !important; }
			&.clear-all, &.show-all { color: $black; cursor: pointer; opacity: 0.65; padding: 18px 20px; border: 1px solid $black; background-color: transparent; }

			input[type="checkbox"] {
				margin-right: 4px;
			}

			label {
				display: inline-block;
				cursor: pointer;
			}
		}
	}
	
	

	table {
		font-size: 1rem;
		
		th {
			border: 1px solid #ddd;
		}
	}

	.fc-toolbar {
		margin-bottom: 30px;

		// .fc-left, .fc-right {
		// 	margin-top: 9px;
		// }

		.fc-button {
			height: 52px;
			font-weight: $medium;
			font-size: 1.184rem;
			line-height: 1;
			color: $black;
			display: inline-block;
			cursor: pointer;
			opacity: 0.65;
			margin: 10px 6px 0 0;
			padding: 14px 15px;
			border: 1px solid $black;
			border-radius: 0;
			background-color: transparent;
			background-image: none;
			text-shadow: none;
			box-shadow: none;
			
			@media(max-width: 900px) {
				font-size: 1rem;
				padding: 10px 11px;
				height: 40px;
			}

			&.fc-state-active {
				z-index: 1;
				opacity: 1;
			}
		}
	}

	table {
		font-family: $fontStack;

		th {
			font-weight: $medium;
			color: $blue;
		}

		td {
			padding: 0;
		}

		.fc-view {
			padding: 30px 0;
		}

		.fc-day-top {
			padding: 5px;
		}

		.fc-event {
			font-size: 1rem;
			font-family: $fontStack;
			display: block;
			cursor: pointer;
			margin-top: 5px;
			padding: 14px 16px;
			border: none;
			border-radius: 0;
			color: #fff;
			font-weight: 500;
			transition: 0.25s;
			
			@media(max-width: 900px) {
				font-size: 0.8rem;
				padding: 10px 12px;
			}
			
			&:hover {
				background: #2d2d2d !important;
			}
		}

		.fc-day-grid-event .fc-content {
			white-space: normal;

			.fc-time {
				display: none;
			}
		}
	}

	.fc-popover {
		font-family: $fontStack;

		.fc-header {
			padding: 4px;
			background-color: $grey;
		}

		.fc-event {
			padding: 4px 6px;
			border: none;
			border-radius: 0;
		}
	}

	td a {
	&.one-day { background-color: var(--one-day-courses) !important; }
	&.online-courses { background-color: var(--online-courses) !important; }
	&.rya-practical-courses { background-color: var(--practical-courses) !important; }
	&.rya-theory-courses { background-color: var(--theory-courses) !important; }
	&.power-courses { background-color: var(--power-courses) !important; }
	&.commercial-courses { background-color: var(--commercial-courses) !important; }
	&.specialist-courses { background-color: var(--specialist-courses) !important; }
	}
}

.course-calendar-page-content table td.fc-day, .course-calendar-page-content .fc-view > table {
	border: solid 1px #ddd !important;
}

div.fc-widget-header { 
	border-bottom: solid 1px #ddd !important;
}

.fc-view > table {
	margin-bottom: 100px;
}

.calendar-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;
	z-index: 10;
	display: none;
	cursor: pointer;
	opacity: 0.75;
	padding: 0 20px;
	background-color: $black;
}

.calendar-popup {
	position: fixed;
	min-height: 420px;
	z-index: 15;
	display: none;
	padding-right: 40px;
	padding-left: 40px;

	.close {
		position: absolute;
		top: 20px;
		right: 20px;
		color: $blue;
		z-index: 16;
		cursor: pointer;
	}
}

@media(max-width: 800px) {
	.calendar-popup {
		min-height: 0;
		height: 90%;
		overflow: scroll;
		
		.half {
			float: none;
			width: 100%;
		}
		
		.cta-button {
			margin-bottom: 20px;
		}
	}
	

}

@media(max-width: 700px) {
	.fc-left h2 {
		font-size: 1.3rem;
	}
}

.fc-right {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.fc-toolbar {
	position: relative;
}

.fc-loading {
	display: flex;
	align-items: center;
	font-size: 1.2rem;
	margin-right: 1rem;
	font-weight: bold;
	color: rgba(0,0,0,0.75);
	
	img {
		filter: grayscale(100%);
		height: 3rem;
		margin: 0 1rem !important;
	}
	
	@media(max-width: 500px) {
		display: block;
		min-width: 100%;
		width: 100vw;
		margin: 1rem 0 !important;
		position: absolute;
		top: 40px;
		left: 0px;
		background: rgba(255,255,255,0.7);
		padding: 40px 20px;
		display: flex;
		flex-direction: column;
		z-index: 100;
	}
}

.fc-toolbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	
	.fc-button {
		margin-top: 0 !important;
	}
	
	.fc-clear { display: none !important; }
	.fc-center { display: none !important; }
}