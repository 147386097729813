// ==============================================================================================================
//  Defines styles for resusable buttons
// ==============================================================================================================

.cta-button {
	position: relative;
	font-weight: $medium;
	font-size: 1.698rem;
	text-decoration: none;
	color: $white;
	display: inline-block;
	z-index: 1;
	padding: 15px 28px;
	border-radius: 5px;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		z-index: -1;
		border-radius: 5px;
		background-color: rgba(255,255,255, 0.12);
		transition: width 0.4s ease-in-out;
	}

	.no-touchevents &:hover:before {
		width: 100%;
	}
}