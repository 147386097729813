// ==============================================================================================================
//  Defines styles for the mobile menu
// ==============================================================================================================

.hamburger {
	position: relative;
	width: 36px;
	cursor: pointer;
	float: right;
	display: none;
	z-index: 110;
	margin-top: 34px;

	@media screen and (max-width: 1000px) {
		display: block;
	}

	.line {
		position: relative;
		top: 0;
		width: 100%;
		height: 2px;
		display: block;
		margin-bottom: 9px;
		background-color: $blue;
		transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out 0.4s, top 0.4s ease-in-out 0.45s;

		&.bottom {
			margin-bottom: 0;
		}
	}

	&.open {

		.line {
			transition: top 0.4s ease-in-out, opacity 0.3s ease-in-out 0.4s, transform 0.4s ease-in-out 0.45s;

			&.top {
				top: 11px;
				transform: rotate(45deg);
			}

			&.middle {
				opacity: 0;
			}

			&.bottom {
				top: -11px;
				transform: rotate(-45deg);
			}
		}
	}
}

.mask {
 	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-color: rgba(0,0,0, 0.75);
	opacity: 0;
	transition: opacity 0.5s ease-in-out, z-index 0.05s ease-in-out 0.5s;

	@media screen and (min-width: 1001px) {
		z-index: -1 !important;
		opacity: 0 !important;
	}

	&.open {
		z-index: 101;
		opacity: 1;
		transition: z-index 0.05s ease-in-out, opacity 0.5s ease-in-out 0.05s;
	}
}

.slideout-menu {
	position: fixed;
	top: 0;
	right: -75%;
	width: 75%;
	height: 100vh;
	z-index: 105;
	padding: 50px 0;
	background-color: $white;
	transition: all 0.4s ease-in-out 0.2s;

	@media screen and (min-width: 1001px) {
		right: -75% !important;
	}

	&.open {
		right: 0;

		@media screen and (max-width: 480px) {
			width: 100%;
		}
	}

	.mobile-menu-wrap {
		position: absolute;
		top: 180px;
		left: 0;
		width: 100%;
		height: calc(100vh - 150px);
		overflow: auto;
	}

	nav.mobile-menu {
		padding: 0 30px;

		ul li {
			width: 100%;
			font-size: 1.375rem;
			color: $blue;
			margin-bottom: 32px;

			&.desktop-hide {
				display: block;
			}

			&:last-child {
				margin-bottom: 0;
			}

			a {
				text-decoration: none;
				color: $blue;
			}

			ul {
				display: none;
				margin-left: 20px;

				li {
					font-size: 1.1rem;
					margin-bottom: 16px;

					&:first-child {
						padding-top: 20px;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.mobile-expand {
				position: relative;
				top: 0;
				width: 0;
				height: 11px;
				cursor: pointer;
				display: inline-block;
				margin-left: 18px;
				border-width: 9px 9px 0 9px;
				border-style: solid;
				border-color: $blue transparent transparent;
			}
		}
	}
}