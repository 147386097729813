// ==============================================================================================================
//  Defines styles specific to the Checkout page
// ==============================================================================================================

.woocommerce-notices-wrapper {
	max-width: 1140px;
	margin: 0 auto;
	padding: 0 20px;
}

.woocommerce-cart-form {
	.product-thumbnail {
		display: none;
	}
}

.woocommerce-checkout {
	.wpcf7 {
		max-width: 100%;
	}
	
	.woocommerce-shipping-fields, .woocommerce-additional-fields {
		margin-top: 40px;
	}

	form.checkout .woocommerce-input-wrapper label {
		display: inline-block;
		margin-right: 24px;
		margin-left: 6px;
 
		&:last-child {
			margin-right: 0;
		}
	}

	.cart-collaterals .cart_totals table {
		border-radius: 0;
		background-color: $grey;
	}

	.woocommerce #payment #place_order {
		@extend .cta-button;
		height: auto !important;
		font-size: 1.698rem !important;
		padding: 15px 28px !important;
		background-color: $orange !important;
	}

	p.woocommerce-thankyou-order-received {
		@extend h1;
		margin-bottom: 60px;
	}

	.woocommerce ul.order_details li:before {
		content: "";
		display: none;
	}

	.woocommerce ul.order_details li strong {
		font-size: 1.55em;
	}

	h2.woocommerce-order-details__title {
		margin-bottom: 0;
	}

	p.woocommerce-customer-details--phone,
	p.woocommerce-customer-details--email {
		font-size: 1.313rem;
		line-height: 1.52;
		color: $black;
	}
}