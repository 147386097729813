// ==============================================================================================================
//  Defines styles specific to the Contact page
// ==============================================================================================================

// Map
.contact-map {
	width: 100%;
	height: 450px;

	.map {
		width: 100%;
		height: 450px;
	}
}

@media screen and (max-width: 680px) {

	.wpcf7 {
		max-width: 100%;
	}

	.contact-content-wrap {

		.half {
			width: 100%;
			float: none;
			margin-right: 0;

			&.contact-form {
				margin-bottom: 30px;
			}
		}
	}
}