// ==============================================================================================================
//  Defines styles specific to the FAQs page
// ==============================================================================================================

.accordion-item {
	border-bottom: 1px solid $darkGrey;

	h3 {
		margin-bottom: 0;
	}

	.question-wrap {
		position: relative;
		cursor: pointer;
		padding: 25px 40px 25px 0;

		&:before {
			content: '';
			position: absolute;
			top: 28px;
			right: 15px;
			width: 13px;
			height: 13px;
			display: inline-block;
			border-top: 2px solid $blue;
			border-right: 2px solid $blue;
			transform: rotate(135deg);
			transition: all 0.3s ease-in-out;
		}

		&.active:before {
			transform: rotate(-45deg);
		}
	}

	.answer-wrap {
		display: none;
		padding: 0 0px 25px;
	}
}