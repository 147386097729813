#mega-menu-item-885 {
  visibility: hidden !important;  
}
.event-search {
	position: absolute;
  max-width: 48px;
  border: 3px solid #05acc0;
  border-color: white;
  border-radius: 3px;
  right:50%;
  top: 112px;
  margin-right: -550px;
  padding:4px ​10px;
  overflow: hidden;
  display: flex;
  transition: all 0.3s;
}
.event-search.open {
	max-width: 600px;
  overflow: visible;
  border-color: #05acc0;
}

.event-search .autocomplete {
  position: relative;
  display: inline-block;
  width: 500px;
}
.event-search input {
  border: 1px solid transparent;
  background-color: #fff;
  font-size: 16px;
  outline:none;
  opacity: 0;
  transition: opacity 0.3s;
}
.event-search.open input {
  opacity: 1;
  padding-left: 10px;
}
.event-search input[type=text] {
  background-color: #fff;
  width: 100%;
  outline: none;
}
.event-search .autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
}
.event-search .autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}
.event-search .autocomplete-items div:hover {
  background-color: #e9e9e9;
}
.event-search .autocomplete-active {
  background-color: #05acc0 !important;
  color: #ffffff;
}
.event-search .autocomplete-search {
	background: white;
	color: #05acc0;
  cursor:pointer;
}

@media screen and (max-width: 1150px) {
  .event-search {
    position: absolute;
    right:20px;
    margin-right: 0;
    display: flex;
  }
}
@media screen and (max-width: 768px) {
  .event-search {
    display: none;
  }
}