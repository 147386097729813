// ==============================================================================================================
//  Defines styles for resusable link blocks
// ==============================================================================================================

.link-blocks {

	.link-block {
		margin-bottom: 65px;

		a {
			text-decoration: none;
		}

		img {
			margin: 0 0 24px;
		}

		p {
			margin-bottom: 10px;
		}

		.learn-more {
			color: $orange;
			display: inline-block;
			margin-bottom: 0;

			&:after {
				content: '';
				position: relative;
				top: -1px;
				width: 100%;
				height: 1px;
				display: block;
				background-color: $orange;
				transition: width 0.3s ease-in-out;
			}
		}

		.no-touchevents &:hover .learn-more:after {
			width: 118%;
		}

		@media screen and (max-width: 768px) {
			width: calc(50% - 10px);

			&:last-child,
			&:nth-child(3n+3) {
				margin-right: 20px;
			}

			&:last-child,
			&:nth-child(2n+2) {
				margin-right: 0;
			}
		}

		@media screen and (max-width: 530px) {
			width: 100%;
			float: none;
			margin-right: 0 !important;
			margin-bottom: 30px;
		}
	}
}