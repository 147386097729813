//==============================================================================================================
//  Defines the fonts/font sizes/line heights/colours etc. of our global text
// ==============================================================================================================

// Global fonts
body {
	font: normal $regular 1rem/1 $fontStack;
}

// Headings
h1, h2, h3, h4, h5, h6 {
	font-weight: $medium;
}

h1 {
	font-size: 2.78rem;
	color: $blue; 
	margin-bottom: 28px;
}

h2 {
	font-size: 1.928rem;
	color: $blue;
	margin-bottom: 28px;
}

h3 {
	font-size: 1.428rem;
	color: $blue;
	margin-bottom: 14px;
}

h4 {
	font-size: 2.152rem;
	color: $white;

	&:after {
		content: '';
		width: 47px;
		height: 4px;
		display: block;
		margin-top: 15px;
		background-color: $white;
	}
}

// Paragraphs/span's etc.
p {
	font-size: 1.313rem;
	line-height: 1.52;
	color: $black;
	margin-bottom: 30px;

	&:last-child {
		margin-bottom: 0;
	}
}

strong {
	font-weight: $bold;
}

address {
	font-size: 1.313rem;
	line-height: 1.52;
	color: $black;
}

a {
	color: $blue;
}

.dataTables_scroll {
	white-space: nowrap;
}

label.checkbox {
	line-height: 1 !important;
}

.woocommerce-invalid #checkout-checkbox {
	outline: 2px solid red;
	outline-offset: 2px;
}