.mini-course-booking {
	.product_meta, .price, .product_title {
		display: none !important;
	}
} 

.single-product-page-content {
	.product_meta {
		display: none;
	}
	
	.cart {
		flex-direction: column;
	}

	select {
		border: 1px solid #ccc;
		padding: 5px;
	}
	
	h1 {
		margin-bottom: 0.25rem;
	}
	
	.price {
		color: #77a464;
		font-size: 1.75rem !important;
		font-weight: bold;
	}
	
	.woocommerce-product-details__short-description {
		margin-bottom: 1.5rem;
	} 

	form.cart div.quantity {
		height: 2.3rem;
	}
	
	.woocommerce-breadcrumb {
		font-size: 1rem !important;
		margin-bottom: 2rem !important;
	}
}

.woocommerce #respond input#submit.alt.disabled, .woocommerce #respond input#submit.alt.disabled:hover, .woocommerce #respond input#submit.alt:disabled, .woocommerce #respond input#submit.alt:disabled:hover, .woocommerce #respond input#submit.alt:disabled[disabled], .woocommerce #respond input#submit.alt:disabled[disabled]:hover, .woocommerce a.button.alt.disabled, .woocommerce a.button.alt.disabled:hover, .woocommerce a.button.alt:disabled, .woocommerce a.button.alt:disabled:hover, .woocommerce a.button.alt:disabled[disabled], .woocommerce a.button.alt:disabled[disabled]:hover, .woocommerce button.button.alt.disabled, .woocommerce button.button.alt.disabled:hover, .woocommerce button.button.alt:disabled, .woocommerce button.button.alt:disabled:hover, .woocommerce button.button.alt:disabled[disabled], .woocommerce button.button.alt:disabled[disabled]:hover, .woocommerce input.button.alt.disabled, .woocommerce input.button.alt.disabled:hover, .woocommerce input.button.alt:disabled, .woocommerce input.button.alt:disabled:hover, .woocommerce input.button.alt:disabled[disabled], .woocommerce input.button.alt:disabled[disabled]:hover {
	background-color: #05acc0 !important;
}

.product-listing {

	$root: &; // #{$root}__

	min-width: 0;
	overflow-x: auto;
	overflow-y: visible;
	margin-bottom: 2rem;

	&__content {

		overflow-y: visible;
	}

	&__scrollbox {
		min-width: 0;
		overflow-y: visible;
		overflow-x: auto;
	}

  &__grid {
	display: flex;
	grid-template-rows: 1fr;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	flex-wrap: wrap;
  }

	&__scrollbox-content{
		display: flex;
		grid-template-rows: 1fr;
		grid-auto-columns: auto;
		grid-auto-flow: column;
		overflow-x: auto;
		overflow-y: visible;
		
		.product-preview {
			margin-right: 25px;
		}
	}

	&__sidebar{
		//$colour-dark-blue;
	}
} 

.product-preview {

	$root: &; // #{$root}__

	//@extend .col-lg-3;
	// width: 300px;
	max-width: 300px;
  	width: 100%;
	position: relative;
	overflow: visible;

	a {
		text-decoration: none;
	}

	&__content-wrap {
		border-radius: 10px;

		margin-bottom: 1.5rem;
		overflow: hidden;
		padding-bottom: 1.5rem;
		box-shadow: 0px 2px 13px 1px rgba(0,0,0,0.07);
		height: 95%;
		transition: 0.5s;
		
		&:hover {
			transform: translateY(-2px);
			box-shadow: 0px 2px 18px 1px rgba(0,0,0,0.15);
		}
	}

	&__link {

		&--category {

			padding: 1.75rem 1.5rem;


			&:hover {

			}
		}


	}
	
	&__accent-bar {
		margin: 0;
		padding: 0;
	}

	&__image {
		width: 100%;
		padding-bottom: 80%;
		background-size: cover;
		background-position: center center;
	}

	&__price {

		padding: 0 2rem;
		font-weight: bold;
		color: #05acc0;

	}

	&__desc {

		padding: 1rem 2rem 0.5rem;
		margin-bottom: 0;
		font-size: 1rem !important;

	}

	&__descr-list {
	  font-size: 1rem;
	  padding: 0 2rem 0 3rem;
	  margin: 0 !important;
	  color: #222;

	  li {
		margin: 0!important;
	  }
	}


	&__title {
		font-size: 1.4rem;
		padding: 2rem 2rem .5rem;
		margin: 0 !important;
	}
}

.product-title__wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	h2 {
		font-size: 2rem;
	}
	
	a {
		font-size: 1.4rem;
	}
}

#shipping_method {
	li:before {
		content: '' !important;
	}
	
	li {
		padding-left: 0;
	}
}

.woocommerce ul#shipping_method li input {
	vertical-align: middle !important;
}

.woocommerce-product-attributes-item__value {
	padding-left: 1rem !important;
}

.wapf-field-input {
	input {
		border: 1px solid #ccc;
		padding: 5px;
		margin-top: 10px;
	}
	
	input::placeholder {
		color: #ccc;
	}
} 


	.quantity {
		margin-bottom: 10px;
		display: block !important;
		float: none !important;
		
		&:before {
			content: 'Quantity';
			padding-right: 10px;
			font-weight: bold;
		}
	}

.single_add_to_cart_button {
	margin-top: 1rem !important;
}

.woocommerce div.product form.cart .variations td.label {
	padding-right: 0 !important;
}