// ==============================================================================================================
//  Variables for storing our font sizes/colours etc.
// ==============================================================================================================

// Font stack(s)
$fontStack: brandon-grotesque, Arial, 'Helvetica Neue', Helvetica, sans-serif;

// Font weights
$light:		300;
$regular:   400;
$medium:	500;
$bold: 		700;

// Colours
$blue:			#054160; // (5,65,96)
$turquoise:		#05acc0; // (5,172,192)
$darkTurquoise: #0092ae; // (0,146,174)
$orange:		#ec6524; // (236,101,36)
$pink:			#ec1163; // (236,17,99)
$black:			#231f20; // (35,31,32)
$grey:			#f5f6f7; // (245,246,247)
$darkGrey:		#eeeff0; // (238,239,240)
$white:			#ffffff; // (255,255,255)