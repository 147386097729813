// ==============================================================================================================
//  Defines styles for the banner area
// ==============================================================================================================

.banner {
	position: relative;

	.banner-item {
		position: relative;
		min-height: 785px;
		display: flex;
		align-items: center;
		z-index: 1;
		overflow: hidden;
		.banner-item__background {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 0;
		}
		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			object-fit: cover;
		}

		.banner-text {
			position: relative;
			max-width: 675px;
			z-index: 3;
			margin: 0 auto;

			p {
				font-size: 1.459rem;
				color: $black;
			}
			
			@media(max-width: 680px) {
				img {
					display: none;
				}
			}
		}

		.overlay {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			background-color: rgba(255,255,255, 0.65);
		}

		@media screen and (max-width: 680px) {
			min-height: 420px;
		}
	}
}