// ==============================================================================================================
//  Defines styles for resusable anchor links
// ==============================================================================================================

.anchor-links-nav {

	> .container {
		padding: 0;
	}

	ul li {
		font-size: 1.15rem;
		display: inline-block;
		font-weight: 500;
		margin-right: 0.5em;
		margin-bottom: 0.5em;

		a {
			text-decoration: none;
			color: $blue;
			display: block;
			padding: 24px;
			
			&:hover {
				background: rgba(0,0,0,0.05);
				
			}
		}
	}
}