// ==============================================================================================================
//  Defines styles specific to the Home page
// ==============================================================================================================

// Banner CTA buttons
.banner-cta-buttons {
	position: relative;
	z-index: 2;
	margin-top: -150px;

	.banner-cta-button {
		min-height: 345px;

		a {
			text-decoration: none;
		}

		.image-wrap {
			height: 260px;
			overflow: hidden;
		}

		.button-image {
			height: 260px;
			transition: transform 0.3s ease-in-out;
		}

		&:hover .button-image {
			transform: scale(1.1) rotate(0.01deg);
		}

		.button-text {
			padding: 24px 0 26px;

			h2 {
				color: $white;
				margin-bottom: 0;
			}
		}

		@media screen and (max-width: 680px) {
			width: 100%;
			float: none;
			margin-right: 0;
			margin-bottom: 20px;
		}
	}

	@media screen and (max-width: 680px) {
		margin-top: 0;
	}
}

// About us/testimonials
.about-us .col-block {
	width: 50%;
	margin-right: 0;
	padding-right: 130px;
	padding-left: 130px;

	@media screen and (max-width: 1400px) {
		padding-right: 80px;
		padding-left: 80px;
	}

	@media screen and (max-width: 1200px) {
		padding: 60px 40px;
	}

	@media screen and (max-width: 1140px) {
		padding-right: 20px;
		padding-left: 20px;
	}

	@media screen and (max-width: 680px) {
		width: 100%;
		float: none;
		margin-right: 0;
		padding: 30px 20px;
	}
}

.main-content img.tripadvisor-logo {
	float: right;
	margin: -75px 0 0;
}


.wprs_unslider {
	margin-bottom: 30px !important;
	
	li.wprs_unslider-active {
		&:before {
			display: none;
		}
		
		padding: 0;
	}
}

@media(max-width: 680px) {
	.tripadvisor-logo {
		display: none;
	}
}