// ==============================================================================================================
//  Defines styles for a resusable two column panel (50% image, 50% text)
// ==============================================================================================================

.two-cols-content > .container {
	position: relative;
	display: flex;
	align-items: center;

	@media screen and (max-width: 680px) {
		display: block;
	}
}

.two-col-text {

	&.text-on-left {
		padding-right: 110px;

		@media screen and (max-width: 920px) {
			padding-right: 45px;
		}

		@media screen and (max-width: 768px) {
			padding-right: 10px;
		}

		@media screen and (max-width: 680px) {
			padding-right: 0;
		}
	}

	&.text-on-right {
		padding-left: 110px;

		@media screen and (max-width: 920px) {
			padding-left: 45px;
		}

		@media screen and (max-width: 768px) {
			padding-left: 10px;
		}

		@media screen and (max-width: 680px) {
			padding-left: 0;
		}
	}

	.further-text-wrap {
		display: none;
		margin-bottom: 30px;
	}
}

.two-col-image {

	img {
		margin: 0;
	}

	.image-wrap.owl-carousel {

		.owl-nav {

			button.owl-next,
			button.owl-prev {
				position: absolute;
				top: calc(50% - 25px);
				width: 13px;
				height: 25px;
				text-align: center;
				display: inline-block;

				img {
					transition: opacity 0.3s ease-in-out;
				}

				&.disabled {

					img {
						opacity: 0.25;
					}
				}
			}

			button.owl-next {
				right: 15px;
			}

			button.owl-prev {
				left: 15px;
			}
		}

		.owl-dots {
			position: absolute;
			bottom: -35px;
			width: 100%;
			text-align: center;
		}

		.owl-dot {
			width: 14px;
			height: 14px;
			border: 1px solid $blue;
			border-radius: 50%;
			display: inline-block;
			margin-right: 4px;
			transition: background-color 0.3s ease-in-out;

			&:last-child {
				margin-right: 0;
			}

			&.active {
				background-color: $blue;
			}
		}
	}

	&.left {
		left: 30px;
		margin: 0;

		+ .two-col-text {
			float: right;
		}
	}

	&.right {
		right: 30px;
		margin: 0;
	}
}

@media screen and (max-width: 680px) {
	
	.two-cols-content .half {
		width: 100%;
		float: none;
		margin-right: 0;

		&:first-child {
			margin-bottom: 30px;
		}
	}
}