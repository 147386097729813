// ==============================================================================================================
//  Defines base styles to be applied throughout the entire site
// ==============================================================================================================

html {
	scroll-behavior: smooth;
}

// Body font smoothing
body {
	 -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Links
a {
	transition: all 0.3s ease-in-out;

	&[href^="tel:"] {
		text-decoration: none;
	}
}

// Main content
.main-content {
	padding: 60px 0;
	
	p {
		font-size: 1.313rem;
		line-height: 1.52;
		color: $black;
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul, ol {
		margin-bottom: 30px;

		li {
			position: relative;
			font-size: 1.313rem;
			line-height: 1.52;
			color: $black;
			margin-bottom: 8px;
			padding-left: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	ul li {

		&:before {
			content: "\2022";
			position: absolute;
			top: 0;
			left: 0;
			color: $blue;
		}
	}

	ol li {
		counter-increment: count-me;
		padding-left: 22px;

		&:before {
			content: counter(count-me)". ";
			position: absolute;
			top: 0;
			left: 0;
			color: $blue;
		}
	}

	p a,
	ul li a,
	ol li a {
		text-decoration: underline;
		color: $blue;
	}

	img {
		margin: 30px 0;
	}

	&.full-width-image img {
		width: 100%;
		margin: 0;
	}

	.iframe-wrap {
		position: relative;
		max-width: 100%;
		height: auto;
		overflow: hidden;
		margin: 30px 0;
		padding-bottom: 56.25%;

		video,
		iframe,
		object,
		embed {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.table-wrap {
		padding: 30px 0;
	}

	table {
		width: 100%;
		font-size: 1.313rem;
		line-height: 1.52;
		text-align: left;
		color: $black;

		tr {
			width: 100%;
		}

		th {
			font-weight: $bold;
			line-height: 1;
			padding: 10px;
		}

		td {
			@extend th;
			font-weight: $regular;

			a {
				text-decoration: none;
				color: $blue;
			}
		}
	}

	p.wp-caption-text {
		font-weight: $light;
		font-style: italic;
		font-size: 1rem;
		text-align: right;
		color: $black;
		margin: 5px 0 20px;
	}
}

// WordPress captions
.wp-caption {
	max-width: 100%;

	img {
		margin-bottom: 0;
	}
}

// Legacy IE notice
.legacy-browser-notice {
	position: fixed;
	bottom: 0px;
	z-index: 9999;
	width: 100%;
	max-width: 1000px;
	font-size: 16px;
	font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans, Helvetica Neue, Arial, sans-serif;
	line-height: 1.6;
	text-align: center;
	color: $white;
	padding: 20px 10px 26px;
	background: url(../../dist/img/legacy-browser-logo.jpg) no-repeat 30px center #00a1f1;

	.notice {
		font-size: 26px;
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	strong {
		font-weight: $bold;
	}

	a {
		font-weight: $bold;
		color: $white;
	}
}