// ==============================================================================================================
//  Defines styles specific to the Basket page
// ==============================================================================================================

.woocommerce-cart {

	.woocommerce table.shop_table {
		border-collapse: collapse;
		border: 0;

		td {
			border: 0;
		}

		td.actions {
			padding: 9px 0;
			border-top: 1px solid #ddd;
		}
	}

	.qib-button:not(#qib_id):not(#qib_id),
	.qib-button:hover:not(#qib_id):not(#qib_id) {
		font-size: 28px;
		cursor: pointer;
		border: 0;
		background-color: transparent;
	}

	.qib-container {
		position: relative;
		left: -30px;

		@media screen and (max-width: 768px) {
			position: static;
			left: auto;
		}
	}

	.qib-container .quantity input.qty:not(#qib_id):not(#qib_id),
	.qib-container .quantity input.qty:focus:not(#qib_id):not(#qib_id) {
		font-size: 1.313rem;
		border: 0;
		background-color: $white;
	}

	.woocommerce #content table.cart td.actions .input-text,
	.woocommerce table.cart td.actions .input-text,
	.woocommerce-page #content table.cart td.actions .input-text,
	.woocommerce-page table.cart td.actions .input-text {
		width: 200px;
		height: 49px;
		font-size: 1.313rem;
	}

	.woocommerce .button[name="apply_coupon"],
	.woocommerce .button[name="update_cart"] {
		font-weight: $regular;
		color: $black;
		border: 1px solid #d3ced2;
		border-radius: 0;
		background-color: transparent;
	}

	.woocommerce button.button:disabled:hover,
	.woocommerce button.button:disabled[disabled]:hover {
		background-color: transparent;
	}

	.cart-collaterals .cart_totals table {
		border-radius: 0;
		background-color: $grey;
	}

	.wc-proceed-to-checkout a.checkout-button {
		@extend .cta-button;
		height: auto !important;
		font-size: 1.698rem !important;
		padding: 15px 28px !important;
		background-color: $orange !important;
	}

	.woocommerce table.shop_table_responsive tr:nth-child(2n) td {
		background-color: transparent;
	}

}

.return-to-shop {
	display: none;
}