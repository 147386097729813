// ==============================================================================================================
//  Defines styles specific to the individual Course pages
// ==============================================================================================================

// Course details block
.course-details {

	p.course-meta {
		font-size: 1.459rem;
		margin-bottom: 0;

		span {
			font-weight: $medium;
		}
	}

	.course-short-description {
		margin-top: 30px;
	}
}

// Course booking block
.mini-course-booking,
.full-course-booking {
	padding: 60px;

	h2 {
		font-weight: $regular;
		font-size: 1.709rem;
		color: $black;
	}

	.select-wrap {
		position: relative;

		&:after {
			content: "\f0dd";
			position: absolute;
			top: 12px;
			right: 20px;
			font-weight: 900;
			font-size: 1.8rem;
			font-family: "Font Awesome 5 Free";
			line-height: 1;
			color: #7e7d7d;
			display: block;
			pointer-events: none;
		}
	}

	select {
		width: 100%;
		height: 65px;
		font-size: 1.313rem;
		color: #7e7d7d;
		margin: 0;
		padding: 0 20px;
		border: 1px solid #aaa;
		background-color: $white;
		appearance: none;

		option {
			font-size: 1rem;
		}
	}

	.woocommerce div.product & form.cart .reset_variations {
		color: #7e7d7d;
	}

	.woocommerce div.product & form.cart .variations {
		margin-bottom: 0;
	}

	.woocommerce div.product & form.cart .woocommerce-variation.single_variation {
		font-size: 1.3rem;
		margin-bottom: 15px;
	}

	span.new-qty-label {
		font-weight: $regular;
		font-size: 1.313rem;
		color: $black;
		float: left;
		margin: 6px 24px 0 0;
	}

	.qib-container:not(#qib_id):not(#qib_id) {
		display: block !important;

		&:after {
			content: "";
			height: 0;
			display: block;
			visibility: hidden;
			clear: both;
		}
	}

	.qib-button:not(#qib_id):not(#qib_id),
	.qib-button:hover:not(#qib_id):not(#qib_id) {
		font-size: 28px;
		cursor: pointer;
		border: 0;
		background-color: transparent;
	}

	.qib-container .quantity input.qty:not(#qib_id):not(#qib_id),
	.qib-container .quantity input.qty:focus:not(#qib_id):not(#qib_id) {
		border: 1px solid #aaa;
		background-color: $white;
	}

	.woocommerce div.product & form.cart .single_add_to_cart_button {
		@extend .cta-button;
		height: auto !important;
		margin: 36px 0 0 !important;
		padding: 15px 28px !important;
		background-color: $orange;
	}
}

.full-course-booking {
	border-top: 1px solid $darkGrey;
	border-bottom: 1px solid $darkGrey;
}

.full-course-booking-wrap {

	// .woocommerce div.product & form.cart .variations {
	// 	width: 50%;
	// 	float: left;
	// }

	// .single_variation_wrap {
	// 	width: 50%;
	// 	float: left;
	// }

}

ul.course-booking-list {
	list-style: none;
	margin: 0;
	padding: 0;
	
	a {
		color: #fff;
		text-decoration: none;
		font-weight: bold;

		li {
			margin: 0 0 10px 0 !important;
			padding: 10px 15px;
			background: #054160;
			color: #fff;
			border-left: solid 5px #05acc0;
			transition: background 0.15s linear;
			
			&:hover {
				background: #05acc0;
			}
			
			&:before {
				display: none;
			}
		}
	}
}

.events-expander {
	max-height: 420px;
	overflow: hidden;
	position: relative;
	transition: 0.5s;
	
	&-expanded {
		max-height: none;
		
		.events-expander-toggle {
			display: none;
		}
	}
	
	&:focus-within {
		max-height: none;
		
		.events-expander-toggle {
			display: none;
		}
	}
	
	&-toggle {
		background: red;
		position: absolute;
		text-align: center;
		padding: 20px 20px 20px;
		height: 62px;
		line-height: 1;
		width: 100%;
		top: 358px;
		left: 0;
		background: linear-gradient(0deg, rgba(245,246,247,1) 0%, rgba(245,246,247,1) 50%, rgba(0,0,0,0) 100%);
		color: $blue;
		font-weight: bold;
		font-size: 22px;
		cursor: s-resize;
		transition: 0.25s;
		&:hover {
			color: $pink;
		}
	}
}

form.cart {
	display: flex;
	
	input {
		height: 100%;
		margin-right: 15px;
		border: solid 1px rgba(0,0,0,0.1);
	}
}

body.woocommerce #respond input#submit.alt, body.woocommerce a.button.alt, body.woocommerce button.button.alt, body.woocommerce input.button.alt {
	background-color: $blue;
	transition: 0.25s;
}

body.woocommerce #respond input#submit.alt:hover, body.woocommerce a.button.alt:hover, body.woocommerce button.button.alt:hover, body.woocommerce input.button.alt:hover {
	background-color: $orange;
}